
import React, { ReactNode, useState, useEffect, useRef } from 'react';
import './App.css'; // Import the CSS for styling

interface CustomTooltipProps {
    content: ReactNode;
    children: ReactNode;
  }
  
  const CustomTooltip: React.FC<CustomTooltipProps> = ({ content, children }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const [indexLanguage, setIndexLanguage] = useState('');

    useEffect(() => {
      // Get the language from localStorage when the component mounts
      const storedLanguage = localStorage.getItem('pxlLanguage') || 'en'; // Default to 'en' if not found
      setIndexLanguage(storedLanguage);
    }, []); // Empty dependency array ensures this runs once on mount
  
    return (
      <span
        className="custom-tooltip-container"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        style={{ zIndex: '10000000000' }}
      >
        {children}
        {showTooltip && <span className="custom-tooltip" style={{ zIndex: '10000000000' }}>{content}</span>}
      </span>
    );
  };
  
  export default CustomTooltip;