import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import FundsComponent from './FundsComponent';
import CustomTooltip from './toolTip';

const StripeAccountLinkLazy = lazy(() => import('./StripeAccountLink'));
const TransactionActionLazy = lazy(() => import('./transactionHistory'));


const WalletsManage = () => {
  const [showModal, setShowModal] = useState(false);
  const modalRefWallet = useRef(null);
  const [wallet, setWallet] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [ethBalance, setEthBalance] = useState(0);
  const [nowValueEth, setNowValueEth] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [stripeAvailableBalance, setStripeAvailableBalance] = useState(0);
  const [stripePendingBalance, setStripePendingBalance] = useState(0);
  const [stripeTotalBalance, setStripeTotalBalance] = useState(0);
  const [indexLanguage, setIndexLanguage] = useState('en');
  const [exchangeRates, setExchangeRates] = useState<{ USD: number; EUR: number } | null>(null);
  const [stripeWalletStatus, setStripeWalletStatus] = useState('inactive');

  // Fetch language from localStorage
  useEffect(() => {
    const storedLanguage = localStorage.getItem('pxlLanguage') || 'en';
    setIndexLanguage(storedLanguage);

   
  }, [showModal]);

 

  // Fetch exchange rates
  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        
        const usdRateString = localStorage.getItem('usdRate');
        const eurRateString = localStorage.getItem('eurRate');

        // Convert string to number using parseFloat. Use 0 or any default value as fallback if null
        const usdRate = usdRateString ? parseFloat(usdRateString) : 1;
        const eurRate = eurRateString ? parseFloat(eurRateString) : 1;

        setExchangeRates({ USD: usdRate, EUR: eurRate });
        
        
      } catch (error) {
        console.error('Error fetching exchange rates:', error);
      }
    };
    fetchExchangeRates();

  }, [showModal]);


  // Handle click outside to close modal
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Now TypeScript knows modalRefWallet.current is an HTMLDivElement or null
      if (modalRefWallet.current && !(modalRefWallet.current as any).contains(event.target as Node)) {
        setShowModal(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showModal]);

  // Fetch wallet and stripe balance when modal is shown or currency changes
  useEffect(() => {
    const savedWalletAddress = localStorage.getItem('walletAddress');
    const savedUserId = localStorage.getItem('DIDI');
    const testSelectedCurrency = localStorage.getItem('pxlCurrency') || 'USD';

    setWallet(savedWalletAddress || ''); // Use an empty string as a fallback
    setSelectedCurrency(testSelectedCurrency);

    const fetchData = async () => {
      if (savedWalletAddress) {
        await getWalletInfo(savedWalletAddress, testSelectedCurrency);
      }
    };

    if (showModal) {
      fetchData();
    }
  }, [showModal, selectedCurrency, exchangeRates]);

  useEffect(() => {
    const stripe_action_required = async () => {
      // Get the walletAddress of the current user
      const walletAddress = localStorage.getItem('walletAddress');

      if (!walletAddress) {
        setStripeWalletStatus('inactive');
        return;
      }

      // Fetch the Stripe connect ID
      const metadataURL = `https://peaxel.me/wp-json/custom/v1/get-metadata-by-metadata/?metadata_name_A=wallet_address&value_metadata_name_A=${walletAddress}&metadata_name_B=stripe_connect_id`;
      const responseMetadata = await fetch(metadataURL);
      const metadata = await responseMetadata.json();
      const stripeConnectId = metadata.meta_value_B;

      //console.log('stripeConnectId',stripeConnectId);


      if (stripeConnectId) {
        // Check if the Stripe account is active
        const statusURL = `https://peaxel.me/wp-json/custom/v1/check-account-status/?account_id=${stripeConnectId}`;
        const responseStatus = await fetch(statusURL);
        const accountData = await responseStatus.json();

      

        if (accountData.transfers_capability_status === 'active') {
          setStripeWalletStatus('active');


          const availableAmount = parseInt(accountData.balance.available[0].amount, 10) / 100; // Convert cents to dollars
          const pendingAmount = parseInt(accountData.balance.pending[0].amount, 10) / 100; // Convert cents to dollars
          
         
          if (exchangeRates) {

            let convertedAvailable = 0;
            let convertedPending = 0;
            let convertedTotal = 0;

            
          if (accountData.balance.available[0].currency === 'usd'){
            if (selectedCurrency === 'USD') {
              convertedAvailable = availableAmount;
            }
            if (selectedCurrency === 'EUR') {
              convertedAvailable = availableAmount/exchangeRates.USD*exchangeRates.EUR;
            }
          } 

          if (accountData.balance.available[0].currency === 'eur'){
            if (selectedCurrency === 'EUR') {
              convertedAvailable = availableAmount;
            }
            if (selectedCurrency === 'USD') {
              convertedAvailable = availableAmount/exchangeRates.EUR*exchangeRates.USD;
            }
          } 

          if (accountData.balance.pending[0].currency === 'usd'){
            if (selectedCurrency === 'USD') {
              convertedPending = pendingAmount;
            }
            if (selectedCurrency === 'EUR') {
              convertedPending = pendingAmount/exchangeRates.USD*exchangeRates.EUR;
            }
          } 

          if (accountData.balance.pending[0].currency === 'eur'){
            if (selectedCurrency === 'EUR') {
              convertedPending = pendingAmount;
            }
            if (selectedCurrency === 'USD') {
              convertedPending = pendingAmount/exchangeRates.EUR*exchangeRates.USD;
            }
          } 
          convertedTotal = convertedPending+convertedAvailable;
          

          setStripeAvailableBalance(parseFloat(convertedAvailable.toFixed(2)));
          setStripePendingBalance(parseFloat(convertedPending.toFixed(2)));
          setStripeTotalBalance(parseFloat(convertedTotal.toFixed(2)));

  
          
        }


        } else {
          /// CASE STRIPE ACCOUNT NOT ACTIVE
        setStripeWalletStatus('inactive');

        const URLAPI = `https://peaxel.me/wp-json/custom/v1/calculate-sums/?wallet_address=${walletAddress}`;
        const responseCalculus = await fetch(URLAPI);
        const calculus = await responseCalculus.json();

      
        if (calculus[0].sum_dollar_value === null){
            const calculusDollar = '0';
            const calculusEuro = '0';
            const convertedAvailable = calculusEuro;

        setStripeAvailableBalance(parseFloat(convertedAvailable));
        setStripePendingBalance(0);
        setStripeTotalBalance(parseFloat(convertedAvailable));
        }

        else {
          const calculusDollar = calculus[0].sum_dollar_value;
          const calculusEuro = calculus[0].sum_euro_value;
        

        if (selectedCurrency === 'EUR') {
        const convertedAvailable = calculusEuro;

        setStripeAvailableBalance(parseFloat(convertedAvailable));
        setStripePendingBalance(0);
        setStripeTotalBalance(parseFloat(convertedAvailable));
        }

        if (selectedCurrency === 'USD') {
        const convertedAvailable = calculusDollar;

        setStripeAvailableBalance(parseFloat(convertedAvailable));
        setStripePendingBalance(0);
        setStripeTotalBalance(parseFloat(convertedAvailable));
        }
      }

       

        }
      } 
      else {

        /// CASE STRIPE ACCOUNT DOES NOT EXIST (ADMIN)

        setStripeWalletStatus('inactive');

        const URLAPI = `https://peaxel.me/wp-json/custom/v1/calculate-sums/?wallet_address=${walletAddress}`;
        const responseCalculus = await fetch(URLAPI);
        const calculus = await responseCalculus.json();
        //console.log('calculus',calculus);
        const calculusDollar = calculus[0].sum_dollar_value;
        const calculusEuro = calculus[0].sum_euro_value;

        if (selectedCurrency === 'EUR') {
        const convertedAvailable = calculusEuro;

        setStripeAvailableBalance(parseFloat(convertedAvailable));
        setStripePendingBalance(0);
        setStripeTotalBalance(parseFloat(convertedAvailable));

        }

        if (selectedCurrency === 'USD') {
        const convertedAvailable = calculusDollar;

        setStripeAvailableBalance(parseFloat(convertedAvailable));
        setStripePendingBalance(0);
        setStripeTotalBalance(parseFloat(convertedAvailable));


        }


      }
    };

    stripe_action_required();
  }, [exchangeRates,showModal]);


  const handleCopyToClipboard = () => {
    if (wallet) {
      navigator.clipboard.writeText(wallet);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1000);
    }
  };

  const getWalletInfo = async (walletAddress:any, selectCurrency:any) => {
    // Fetch ETH balance and convert based on selected currency and exchange rates
    const ethResponse = await fetch(`https://api.sandbox.x.immutable.com/v2/balances/${walletAddress}`);
    const ethData = await ethResponse.json();
    const ethBalance = parseFloat(ethData.result[0].balance) / 1e18;
    setEthBalance(ethBalance);
    if (exchangeRates) { 
    const nowValue = selectCurrency === 'USD' ? ethBalance * exchangeRates.USD : ethBalance * exchangeRates.EUR;
    setNowValueEth(nowValue);
  }
  };

  function renderStripeLink() {
    const className = stripeWalletStatus === 'active' ? "stripe_settings" : "portfolio-button";
    return (
      <span className={className}>
        <Suspense fallback={<div>Loading...</div>}>
          <StripeAccountLinkLazy />
        </Suspense>
      </span>
    );
  }



  return (


    <div>
     
      <span onClick={() => setShowModal(true)} className="button-search">
        <i className="fa-solid fa-wallet"></i> {indexLanguage === 'en' ? '' : ''}
      </span>

      {showModal && (
        <div className="aroundModal">
        <div className="modal-container" ref={modalRefWallet}>
        
        <button
              className="close-button-modal" 
              onClick={() => setShowModal(false)}>
             <i className="fa-regular fa-circle-xmark"></i>
            </button>



          <div className="orgaWallets">

            <div className="ETHwallet">
             <span> <span className="wallet-menu-title"><i style={{ opacity: 0.5 }} className="fa-solid fa-coins"></i>&nbsp;&nbsp;CASH WALLET</span> 
              

              {stripeWalletStatus === 'active' ? (
  <span className="stripeOK">&nbsp;</span>
) : (
  <span className="stripeNOTOK">
    
    <CustomTooltip
      content={
        <div style={{ zIndex: '10000000000' }}>
          <p><strong>Activation Required</strong></p>
          <p style={{ opacity: 1 }}>
            Your Stripe account requires activation to fully utilize the platform. Please complete the necessary steps to activate your account and enable payouts.
          </p>
          <p style={{ opacity: 1 }}>This may include verifying your identity and providing additional information as required by laws.</p>
        </div>
      }
    >
      <span className="activationRequired">&nbsp;&nbsp;ACTIVATION REQUIRED &nbsp;<button className="button_tooltip_wallet"><i className="fa-solid fa-circle-info"></i></button>&nbsp;&nbsp;</span>
    </CustomTooltip>
  </span>
)
    }    
              
            
              
              
              </span>
              <span className="wallet-menu-currency">{selectedCurrency === 'USD' ? '$' : ''}{stripeTotalBalance.toFixed(2)}{selectedCurrency === 'USD' ? '' : '€'}</span>
              
<span className="wallet-menu">
  {selectedCurrency === 'USD' ? '$' : '€'}
  {stripeAvailableBalance.toFixed(2)}
  <span>
    {stripeWalletStatus === 'active' ? " - Available for Payout | Daily" : " - Hold by Peaxel until activation"}
  </span>
</span>

<span className="wallet-menu">
  <span>
    {selectedCurrency === 'USD' ? '$' : '€'}
    {stripePendingBalance.toFixed(2)}
    {stripeWalletStatus === 'active' ? " - Hold for Verification | 7 Days" : " - No payout until activation"}
  </span>
</span>
              
                <br/>
              
   
              {showModal && renderStripeLink()}

            
            </div>
            <div className="ETHwallet">
              <span>
              <span className="wallet-menu-title"><i style={{ opacity: 0.5 }} className="fa-brands fa-ethereum"></i>&nbsp;&nbsp;ETHEREUM&nbsp;&nbsp;&nbsp;</span>
              <span className="wallet-menu" onClick={handleCopyToClipboard}>
                @ {wallet.slice(0, 6)}...{wallet.slice(-4)}&nbsp;&nbsp;<FontAwesomeIcon icon={faCopy} onClick={handleCopyToClipboard} />
                {isCopied && <span className="copied-message">Copied!</span>}
              </span></span>
              <span className="eth-wallet"><span className="wallet-menu-currency">{selectedCurrency === 'USD' ? '$' : ''}{nowValueEth.toFixed(2)}{selectedCurrency === 'USD' ? '' : '€'}</span>
              <span className="wallet-menu">&nbsp;&nbsp;~{ethBalance} ETH</span></span>
              
              <br/>
              <span className="portfolio-button"><FundsComponent /></span>
              <br/>
              <Suspense fallback={<div>Loading...</div>}>
          <TransactionActionLazy />
        </Suspense>
            </div>
          </div>
        </div>
        </div>
      )}

      
    </div>
  );
};

export default WalletsManage;
